import React, { 
    useEffect, 
    useState, 
    useRef
} from "react"
// import 'lazysizes';
// import 'lazysizes/plugins/attrchange/ls.attrchange';
import Lazysizes from '../../imageHandler/lazysizes'
import style from './post.module.scss'


import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';

// Params for Swiper
const params = {
    loop: true,
    loopedSlides: 4,
    mode: 'horizontal',
    slidesPerView: "auto",
    mousewheel: true,
    activeSlideKey: 0,
    watchSlidesVisibility: true,
    lazy: false,
    navigation: {
      nextEl: style.swiperButtonNext,
      prevEl: style.swiperButtonPrev
    },
    renderPrevButton: () => <div role="button" className="swiper-button-prev">Prev</div>,
    renderNextButton: () => <div role="button" className="swiper-button-next">Next</div>,
  
    freeMode: true,
    breakpoints: {
        1200: {
            freeMode: false,
        },
    },
    shouldSwiperUpdate: true,
    // rebuildOnUpdate: true,
    // runCallbacksOnInit: true,
    // rebuildOnUpdate: true,
}

const PostSwiper = ({ images, imageId, handleShowNext }) => {

    // States
    const [ mySwiper, setMySwiper ] = useState(null)
    const [ vh, setVh ] = useState(typeof window !== 'undefined' ? window.innerHeight * 0.01 : 100)

    const ref = useRef(null);
    //  Slider – Counter
    let slideCounter = 0;

    //  Slider – On slide Change to fade in Next Post
    params.on = {
        init: (swiper) => {
        //   this.swiper = swiper
          setMySwiper(swiper)
        },
        slideChange: () => {
            if(slideCounter > 3){
                handleShowNext()
            }
            slideCounter++
        }
    }

    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            mySwiper.slideNext();
        }
    };
    
    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            mySwiper.slidePrev();
        }
    };

    useEffect(() => {
        if (mySwiper !== null && mySwiper !== undefined) {
            // setMySwiper(newSwiper) 
            mySwiper.on("resize", () => {
                if(typeof window !== 'undefined'){
                    mySwiper.params.centeredSlides = window.innerWidth < 900;
                    mySwiper.update();
                    setVh(window.innerHeight * 0.01)
                }
            });
        }
        // Moved here from function
        const newImageId = imageId || 0

        let timeout;

        timeout = setTimeout(() => {
            if (mySwiper !== null && mySwiper.params && newImageId !== 0) {
                mySwiper.slideToLoop(newImageId, newImageId*200, false);
                // mySwiper.update();
            }
        }, 200)

        return () => { clearTimeout(timeout) }
    }, [mySwiper, imageId])
    // [mySwiper]

    return(
        <div className={style.images} ref={ref}>
            <Swiper {...params} className={style.swiper} >
            {
                images.map((image, i) => {
                    
                    if(!image) return false

                    const height = `calc(${vh*100}px - 150px)`;
                    const width = `calc(${vh*100*image.mediaDetails.width/image.mediaDetails.height}px - 150px)`

                    return(
                        <div key={i} className={style.swiperSlide} style={{height, width}}>
                            <Lazysizes image={image} minSize={600}/>
                        </div>
                    )
                })
            }
            </Swiper>
            <div className={style.swiperButtonPrev} role="button" tabIndex={0} aria-label="swipe left" data-id="test" onClick={goPrev} onKeyDown={goPrev} />
            <div className={style.swiperButtonNext} role="button" tabIndex={0} aria-label="swipe right" data-id="test" onClick={goNext} onKeyDown={goPrev} />
        </div>
    )
}

export default PostSwiper


// <style jsx="true">{`
                            
// img.lazyloaded {
//     opacity: 1;
//     transition: 300ms;
//     filter: none;
//     transform: none;
// }
// img.lazypreload{
//     opacity: 1;
//     transition: 100ms;
// }

// img.swiper-lazy-loaded, img.lazyloaded {
//     opacity: 1;
//     transition: 300ms;
//     filter: none;
//     transform: none;
// }
// `}</style>