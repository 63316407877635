import React, { useState } from 'react'
import PostInfo from './post__info'
import PostNext from './post__next'
import PostSwiper from './post__swiper'
import style from './post.module.scss'

const getNextPost = (nodes, title, location) => {

    const filter = location.state ? location.state.category : false

    let nodeIndex = false

    const filteredNodes = [...nodes].filter((node, i) => {

        let match = false

        if(!filter) return true
        // Only If  Category matches
        node.categories.nodes.forEach((category, i) => {
            // console.log(category.slug, filter, category.slug === filter)
            if(category.slug === filter) match = true
        })

        return match
    })

    // console.log(filteredNodes)

    filteredNodes.forEach((node, i) => {

        if(node.title === title){
            nodeIndex = i
            return false
        }
    })

    const nextNode = nodeIndex !== filteredNodes.length-1 ? filteredNodes[nodeIndex+1] : filteredNodes[0]

    return nextNode

}

const PostWrapper = ({node, allNodes, imageId, location }) => {

    const [ state, setState ] = useState({
        showNext: false,
    })

    const showNext = () => {
        setState({
            showNext: true
        })
    }

    const nextNode = getNextPost(allNodes, node.title, location)

    // console.log(nextNode)

    return(
        <div className={style.project} >
            <PostSwiper images={node.postfields.images} handleShowNext={showNext} imageId={imageId} />
            <PostInfo node={node}/>
            <PostNext nextNode={nextNode} showNext={state.showNext} category={location.state ? location.state.category : false}/>
        </div>
    )
}

export default PostWrapper