// import React, { useEffect } from "react"
import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Transition from '../components/transition/transition'

import PostWrapper from '../components/pages/post/post__wrapper';


export default ({ data, location }) => {

    const [ state ] = useState({
        node: data.wpcontent.post,
        allNodes: data.wpcontent.posts.nodes,
    })
    let imageId = 0

    // Check and get Image ide from previous Page if available, else jump to first image
    imageId = location.state ? location.state.imageId : 0;

    // console.log('post location.state', location.state)
    return (
        <Layout className="page__project">
            <SEO title={state.node.title} />    
            <Transition>
                <main>
                    <PostWrapper 
                        allNodes={state.allNodes} 
                        node={state.node} 
                        imageId={imageId} 
                        location={location}
                    />
                </main>
            </Transition>
        </Layout>
    )
}

export const pageQuery = graphql`
query currentPost($id: ID!) {
    wpcontent{
        posts(first: 200) {
            nodes {
                title
                slug
                categories {
                    nodes{
                        slug
                    }
                }
            }
        }
        post( id: $id ) {
            title
            slug
            categories {
                nodes{
                    slug
                }
            }
            postfields {
                type
                year
                titleExtended
                text
                images {
                    id
                    sourceUrl
                    mediaDetails {
                        width
                        height
                        sizes {
                            width
                            height
                            sourceUrl
                        }
                    }
                }
                credits {
                    label
                    name
                }
                info {
                    name
                    label
                }
            }
        }
    }
}
`
