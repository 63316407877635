import React, { useEffect, useState, useRef } from "react"
import style from './post.module.scss'


const InfoButton = ({isInfoOpen, handleInfo}) => {

    const noTransform = {
        WebkitTransform: "rotate(0deg)", 
        transform: "rotate(0deg)" 
    }

    const rotateClockwise = {
        WebkitTransform: "rotate(45deg)", 
        transform: "rotate(45deg)"
    }
    const rotateCounterClockwise = {
        WebkitTransform: "rotate(-45deg)", 
        transform: "rotate(-45deg)"
    }

    return(
        <div className={style.info__header__button} onClick={handleInfo} onKeyDown={handleInfo} role="button" tabIndex={0}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                {
                    isInfoOpen ?
                    <g>
                        <rect x="1" y="1" width="37" height="37" rx="20" ry="19" stroke="red" strokeWidth="1" style={{opacity: "0"}}></rect>
                        <rect x="18" y="0"  width="1" height="40" style={ rotateCounterClockwise } rx="3" ry="3"  fill="red"></rect>
                        <rect x="19" y="0" width="1" height="40"  style={ rotateClockwise   } fill="red" ></rect>
                    </g>
                    :
                    <g>
                        <rect x="1" y="1" width="37" height="37" rx="20" ry="19" stroke="red" strokeWidth="1" style={{opacity: "1"}}></rect>
                        <rect x="18" y="8"  width="3" height="3"  style={ noTransform } rx="3" ry="3"  fill="red"></rect>
                        <rect x="19" y="15" width="1" height="14" style={ noTransform } fill="red" ></rect>
                    </g>
                }
            </svg>
        </div>
    )
}

const PostInfo = ({ node }) => {
    //  States and References
    const [ isInfoOpen, setIsInfoOpen ] = useState(false)
    const headerRef = useRef()

    const handleOverflow = () => {
        // Avoid issue with server side rendering not recognizing window
        if(typeof window === 'undefined') return false

        // Title – Next Project Reference
        const nextTitle = headerRef.current;
        const screenWidth = window.innerWidth
        // Get title width 
        // ––– const nextTitleWidth = nextTitle ? nextTitle.offsetWidth : 200
        const nextTitleWidth = nextTitle.offsetWidth

        // ––– screenWidth < nextTitleWidth && nextTitle ? nextTitle.classList.add('overflow') : nextTitle.classList.remove('overflow')
        screenWidth < nextTitleWidth ? nextTitle.classList.add('overflow') : nextTitle.classList.remove('overflow')
    }

    useEffect(() => {
        handleOverflow() 
    })

    const handleInfo = () => {
        setIsInfoOpen(!isInfoOpen)
    }


    return(
       <div className={`${style.info} ${isInfoOpen ? style.open : ""}`}>
       {/* <div className={`info ${isInfoOpen ? "open" : ""}`}> */}
            <div className={style.info__header}>
                <div className={style.info__header__title}>
                    <h2 ref={headerRef}>
                    {node.title}
                    </h2>
                </div>
                <div className={style.info__header__subtitle}>
                    <h3>
                        {node.postfields.titleExtended}<br/>
                        {node.postfields.type}<br/>
                        {/* {node.categories.nodes.map((item, i) => <span key={i}>--{item.slug}--</span>)} */}
                        {node.postfields.year}<br/>
                    </h3>
                </div>
                <InfoButton isInfoOpen={isInfoOpen} handleInfo={handleInfo}/>
                
            </div>
            <div className={style.info__wrapper}>
                <div>
                    <div>
                    {
                        node.postfields.info.map((field, i) => (
                            <div key={i} className={style.info__wrapperItem}>
                                <strong>{field.label}</strong><br />
                                <span>{field.name}</span>
                            </div>
                        ))
                    }
                    </div>
                    <div>
                    {
                        node.postfields.credits.map((field, i) => (
                            <div key={i} className={style.info__wrapperItem}>
                                <strong>{field.label}</strong><br />
                                <span>{field.name}</span>
                            </div>
                        ))
                    }
                    </div>
                </div>
                <div>
                    <p dangerouslySetInnerHTML={{__html: node.postfields.text}} />
                </div>
            </div>
        </div>
    )
}

export default PostInfo