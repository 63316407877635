import React, { useState } from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import { exitTransition, entryTransition} from '../../transition/transition'
// import Pattern from '../../pattern'
import style from './post.module.scss'

const PostNext = ({ nextNode, showNext, category }) => {
    
    // console.log('next')
    const [ state ] = useState({
        nextNode: nextNode
    })

    console.log('state.nextNode.slug', state.nextNode.slug)
    
    return(
        <div  className={`${style.next} ${showNext ? style.visible : ""}`} >
            <TransitionLink 
                mode="immediate"
                to={`/projekte/${state.nextNode.slug}`} 
                // exit={exitTransition}
                // entry={entryTransition}
                exit={{ 
                    ...exitTransition, 
                    state: { previousPost: true }, 
                }}
                entry={{ 
                    ...entryTransition, 
                    state: { previousPost: true } ,
                }}
                state={{ 
                    imageId: false, 
                    previousPost: true,
                    category: category
                }}
                aria-label={state.nextNode.title} 
            >
                <div>
                    <div className={style.next__info}>
                        <h3 style={{position: "absolute" }}>{state.nextNode.title}</h3>
                        <div className={style.next__info__arrow}>
                            <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g className={style.arrow}>
                                <path d="M7 10L15 18L23 10" stroke="#FF0000" strokeWidth="1"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className={style.next__pattern}/>
                </div>
            </TransitionLink>
        </div>
    )
}
export default PostNext